@import "@fontsource/space-grotesk";
@import "@fontsource/space-grotesk/400.css";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import "ngx-toastr/toastr";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Space+Grotesk:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sometype+Mono:ital,wght@0,400..700;1,400..700&family=Space+Grotesk:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sometype+Mono:ital,wght@0,400..700;1,400..700&family=Space+Grotesk:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");
:root {
  --color-primary: #151515;
  --color-secondary: #ffd700;
  --color-grey-dark: #7f7f7f;
  --color-white: #ffffff;
  --color-black: #1a1a1a;
  --font-weight-bold: 800;
}
html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Space Grotesk", sans-serif;
}

.zaper-btn {
  display: inline-flex;
  align-items: center;
  padding: 0.8rem 1.5rem;
  background-color: var(--color-primary);
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  animation: slideUp 0.8s ease-out 0.4s backwards;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  .btn-icon {
    display: inline-block;
    width: 16px;
    height: 14px;
    background-image: url("/assets/images/icons/ar-light-right.svg");
    background-size: cover;
    margin-right: 8px;
    transition: background-image 0.2s ease;
  }
  img {
    height: 18px;
    width: 18px;
    margin-right: 13px;
  }
  &:hover {
    background-color: rgb(243, 243, 243);
    color: var(--color-primary);
    transition: 0.2s;
    .btn-icon {
      background-image: url("/assets/images/icons/ar-dark-right.svg");
    }
  }
}

.zaper-btn-light {
  background-color: white;
  color: var(--color-primary);
  .btn-icon {
    background-image: url("/assets/images/icons/ar-dark-right.svg");
  }
  &:hover {
    color: white;
    background-color: rgba(161, 161, 161, 0.205);
    .btn-icon {
      background-image: url("/assets/images/icons/ar-light-right.svg");
    }
  }
}

.zaper-back-btn {
  display: inline-flex;
  align-items: center;
  padding: 15px 25px;
  background-color: var(--color-primary);
  color: white;
  text-decoration: none;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  animation: slideUp 0.8s ease-out 0.4s backwards;
  font-size: 26px;
  font-weight: 400;
  line-height: 33px;

  .btn-icon {
    display: inline-block;
    width: 21px;
    height: 19px;
    background-image: url("/assets/images/icons/ar-light-left.svg");
    background-size: cover;
    margin-right: 15px;
    transition: background-image 0.2s ease;
  }
  &:hover {
    background-color: rgb(243, 243, 243);
    color: var(--color-primary);
    transition: 0.2s;
    .btn-icon {
      background-image: url("/assets/images/icons/ar-dark-left.svg");
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    .btn-icon {
      width: 17px;
      height: 15px;
      margin-right: 12px;
    }
  }
}

.form-container {
  width: 100%;

  .form-group {
    margin-bottom: 1.5rem;
    position: relative;

    label {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: 500;
    }

    .icon {
      position: absolute;
      z-index: 1;
      top: 11px;
      left: 12px;
      img {
        height: 21px;
        width: 21px;
      }
    }
    .zaper-input {
      padding-left: 12px;
    }
    &.suffix {
      .icon {
        left: unset;
        right: 15px;
      }
      input,
      select {
        padding-left: 18px;
      }
    }
    input,
    select {
      width: 100%;
      padding: 0.7rem;
      padding-left: 40px;
      border: 1px solid #e4e4e7;
      border-radius: 0;
      background: white;
      font-size: 1rem;
      &:focus {
        border-radius: 0 !important;
      }
    }

    input::placeholder {
      color: #a1a1aad3;
    }
  }
  .checkbox-group {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 8px;
  }
  .checkbox-group input[type="checkbox"] {
    width: 18px;
    height: 18px;
    accent-color: var(--color-black);
  }
}

.toast-container {
  position: fixed !important;
}

.toast-success {
  background-color: var(--color-primary);
  background-image: url("assets/images/icons/success.png");
  box-shadow: none !important;
  border-radius: 0 !important;
  width: 316px !important;
  padding: 11px 15px 11px 55px !important;
  border-left: 6px solid var(--color-secondary);
  .toast-title {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: white;
  }
  .toast-message {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    color: rgb(198, 198, 198);
  }
  .toast-close-button {
    color: rgba(0, 0, 0, 0.277);
  }
}

.toast-error {
  background-color: var(--color-primary);
  background-image: url("assets/images/icons/error.png");
  box-shadow: none !important;
  border-radius: 0 !important;
  width: 316px !important;
  padding: 11px 15px 11px 55px !important;
  border-left: 6px solid rgb(216, 31, 31);
  .toast-title {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: white;
  }
  .toast-message {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    color: rgb(198, 198, 198);
  }
  .toast-close-button {
    color: rgba(0, 0, 0, 0.277);
  }
}

@include media-breakpoint-up(md) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1448px !important;
  }
}

@include media-breakpoint-down(sm) {
  .zaper-btn {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    padding: 8px 16px;
  }
}
